<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <a-card>
      <template slot="title">
        <strong v-if="action === 'create'">Form Pembayaran</strong>
        <strong v-if="action === 'update'">Update Pembayaran</strong>
        <a-button size="default" type="danger" class="ml-2" @click="resetForm"
          >Back</a-button
        >
      </template>
          <div class="row">
            <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mt-1">
              <label>Payment Date</label>
            </div>
            <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9">
          <a-date-picker
            class=""
            style="width: 120px;"
            v-model="modalInput.payment_date"
            :allowClear="false"
            format="DD-MM-YYYY"
          />
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mt-1">
              <label>Siswa</label>
            </div>
            <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9">
              <a-select
                class=""
                show-search
                placeholder="Pilih Siswa"
                v-model="modalInput.student_id"
                option-filter-prop="children"
                :filter-option="filterOption"
                style="width: 250px"
                @change="filterJadwal"
              >
                <!-- <a-spin v-if="fetching" slot="notFoundContent" size="small" /> -->
                <a-select-option
                  style="width: 100%"
                  v-for="d in masterStudent"
                  :key="d.id"
                >
                  <!-- {{ formatDate(d.start_date) + " - " + formatDate(d.end_date) }} -->
                  {{ d.name }}
                </a-select-option>
              </a-select>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-1">
              <strong for="">Pilih yang akan dibayar</strong>
            </div>
            <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8">
          <a-table
            :scroll="{ y: 300 }"
            :columns="table.column"
            :dataSource="table.datatable"
            :pagination="false"
            size="small"
            :customRow="customRow"
          >
            <template slot="actionButton" slot-scope="text, record, i">
              <a-button
                :class="record.checked ? 'btn btn-outline-danger' : 'btn btn-outline-primary'"
                @click="!record.checked ? selectData(record) : removeData(i)"
                >
                {{ record.checked ? 'Unselect' : 'Select' }}
                </a-button
              >
            </template>
        <span slot="numberFormat" slot-scope="text">
          {{ numberFormat(text) }}
        </span>
        <span slot="dateFormat" slot-scope="text">
          {{ formatDate(text) }}
        </span>
        <span slot="dateFormat" slot-scope="text">
          {{ formatDate(text) }}
        </span>
          </a-table>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mt-1">
              <label for="">Jumlah yg dibayar :</label>
            </div>
            <div class="col-xs-10 col-sm-10 col-md-10 col-lg-10">
              <a-input style="width: 120px;" v-model="mask.amount" :readOnly="true">
              </a-input>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mt-1">
              <label for="">Payment Method</label>
            </div>
            <div class="col-xs-10 col-sm-10 col-md-10 col-lg-10">
              <a-select
                class=""
                show-search
                placeholder="Pilih Payment Method"
                v-model="modalInput.payment_method_id"
                option-filter-prop="children"
                :filter-option="filterOption"
                style="width: 250px"
              >
                <!-- <a-spin v-if="fetching" slot="notFoundContent" size="small" /> -->
                <a-select-option
                  style="width: 100%"
                  v-for="d in masterPaymentMethod"
                  :key="d.id"
                >
                  <!-- {{ formatDate(d.start_date) + " - " + formatDate(d.end_date) }} -->
                  {{ d.name }}
                </a-select-option>
              </a-select>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mt-1">
              <label for="">Notes:</label>
            </div>
            <div class="col-xs-10 col-sm-10 col-md-10 col-lg-10">
              <a-input style="width: 300px;" v-model="modalInput.notes" :readOnly="false">
              </a-input>
                <a-button
                  size="default"
                  :class="action === 'update' ? 'btn btn-warning ml-5' : 'btn btn-primary ml-5'"
                  @click="handleSubmit"
                  >{{ action === 'update' ? 'Save Changes' : 'Submit' }}</a-button
                >
            </div>
          </div>
        </a-card>
      </div>
    </div>
  </div>
</template>

<script>
import * as lou from '@/services/data/lou'
import moment from 'moment'

export default {
  created () {
    this.getMaster()
    if (this.editdata.id !== '') {
      this.action = 'update'
      this.title = 'Update Data'
      this.getEditData()
    } else {
      this.action = 'create'
      this.title = 'Create Data'
    }
  },
  data() {
    return {
      editdata: JSON.parse(
        localStorage.getItem('schoolmanagementformpembayaran'),
      ),
      action: 'create',
      masterSchedule: [],
      masterStudent: [],
      masterPaymentType: [],
      masterPaymentMethod: [],
      masterAcademicYear: [],
      modalInput: {
        payment_date: moment(),
        student_id: '',
        amount: 0,
        payment_method_id: '',
        notes: '',
        details: [],
      },
      mask: {
        amount: '',
      },

      table: {
        column: [
          {
            title: 'Type',
            dataIndex: 'payment_type_name',
            scopedSlots: { customRender: '' },
            // width: 150,
          },
          {
            title: 'Due Date',
            dataIndex: 'due_date',
            scopedSlots: { customRender: 'dateFormat' },
            // width: 150,
          },
          {
            title: 'Amount',
            dataIndex: 'amount',
            scopedSlots: { customRender: 'numberFormat' },
            // width: 150,
          },
          {
            title: 'Academic Year',
            dataIndex: 'academicYear',
            scopedSlots: { customRender: '' },
            // width: 150,
          },
          {
            title: '#',
            // dataIndex: 'actionButton',
            scopedSlots: { customRender: 'actionButton' },
            // width: 150,
          },
          {
            title: 'Pembayaran',
            dataIndex: 'pembayaran',
            scopedSlots: { customRender: 'numberFormat' },
            // width: 150,
          },
        ],
        datatable: [],
      },
    }
  },
  methods: {
    moment,
    async getEditData() {
      // var res = await lou.customUrlGet2('registrasi/simpanan', { where: { id: this.editdata.id } })
      // console.log('this.editdata', this.editdata)
      this.modalInput = this.$g.clone(this.editdata)
      // console.log('this.editdata', this.editdata)
      // this.maskInput.cabang = this.editdata.kode.slice(0, this.editdata.kode.indexOf('.'))
      // this.maskInput.rekode = this.editdata.kode.slice(this.editdata.kode.indexOf('.') + 1, this.editdata.kode.length)
      this.modalInput.payment_date = moment(this.editdata.payment_date, 'YYYY-MM-DD')
      this.mask.amount = this.numberFormat(this.editdata.amount)
      // this.table.datatable = this.editdata.details
      var getdatasche = await lou.customUrlGet2('sekolah/payment/schedule/' + this.editdata.student_id)
      // var tempdata = []
      if (getdatasche) {
        this.table.datatable = getdatasche.data
        // tempdata = getdatasche.data
      }
      // console.log('this.table.datatable', this.table.datatable)
      // console.log('this.editdata.details', this.editdata.details)
      this.table.datatable.forEach(element => {
        // element.id = element.payment_schedule_id
        var target = this.editdata.details.findIndex(x => x.payment_schedule_id === element.payment_schedule_id)
        // console.log('target', target)
        if (target >= 0) {
          element.checked = true
        }
      })
      // this.modalInput.details.forEach(element => {
      //   element.payment_schedule_id = element.payment_schedule_id
      // })
      // this.filterJadwal()
      // console.log('this.editdata', this.editdata)
      // setTimeout(() => {
      //   this.modalInput.kodya = this.editdata.kodya
      //   this.kotaChange()
      // }, 100)
      // setTimeout(() => {
      //   this.modalInput.kecamatan = this.editdata.kecamatan
      //   this.kecamatanChange()
      // }, 300)
      // setTimeout(() => {
      //   this.modalInput.kelurahan = this.editdata.kelurahan
      //   this.kelurahanChange()
    },
    async getMaster() {
      this.masterSchedule = []
      this.masterStudent = []
      this.masterPaymentType = []
      this.masterPaymentMethod = []
      var res = await lou.customUrlGet2('sekolah/paymentschedule')
      var res1 = await lou.customUrlGet2('sekolah/student')
      var res2 = await lou.customUrlGet2('sekolah/payment_type')
      var res3 = await lou.customUrlGet2('sekolah/academic_year')
      var res4 = await lou.customUrlGet2('sekolah/payment/method')
      if (res) {
        this.masterSchedule = res.data
        this.masterStudent = res1.data
        this.masterPaymentType = res2.data
        this.masterAcademicYear = res3.data
        this.masterPaymentMethod = res4.data
      }
    },
    async filterJadwal() {
      var res = await lou.customUrlGet2('sekolah/payment/schedule/' + this.modalInput.student_id)
      // console.log('res', res)
      if (res) {
        this.table.datatable = res.data

        this.table.datatable.forEach(element => {
          // element.payment_type_name = this.findMaster('masterPaymentType', 'id', element.payment_type_id, 'name')
          // element.academic_year = this.findMaster('masterAcademicYear', 'id', element.academic_year_id, 'name')
          element.checked = false
        })
      }
      // var target = this.masterSchedule.filter(x => x.student_id === this.modalInput.student_id)
      // if (target.length !== 0) {
      //   this.table.datatable = target
      //   this.table.datatable.forEach(element => {
      //     element.payment_type_name = this.findMaster('masterPaymentType', 'id', element.payment_type_id, 'name')
      //     element.academic_year = this.findMaster('masterAcademicYear', 'id', element.academic_year_id, 'name')
      //     element.checked = false
      //   })
      // } else {
      //   this.table.datatable = []
      // }
    },
    selectData(record) {
      var findD = this.modalInput.details.findIndex(x => x.payment_schedule_id === record.payment_schedule_id)
      console.log('findD', findD)
      if (findD >= 0) {
        this.ext()
      } else {
        record.checked = true
        this.modalInput.details.push(record)
        this.ext()
      }
      // console.log('this.modalInput.details', this.modalInput.details)
    },
    removeData(index) {
      // console.log('index', index)
      // console.log('this.modalInput.details[index]', this.modalInput.details)
      this.table.datatable[index].checked = false
      var targetIn = this.modalInput.details.findIndex(x => x.payment_schedule_id === this.table.datatable[index].payment_schedule_id)
      if (targetIn >= 0) {
        this.modalInput.details.splice(targetIn, 1)
      }
      this.ext()
    },
    ext() {
      const countOccurrences = arr => arr.reduce((acc, item) => {
        acc[item] = (acc[item] || 0) + 1
        return acc
      }, {})
      var sum = 0
      var notes = []
      this.modalInput.details.forEach(element => {
        element.pembayaran = element.amount
        sum += element.amount
        notes.push(element.payment_type_name)
      })
      var removedup = countOccurrences(notes)
      var getkey = Object.keys(removedup)
      var therealnote = ''
      getkey.forEach(element => {
        // console.log('element', element)
        therealnote += therealnote !== '' ? removedup[element] <= 1 ? ' + ' + element : ' + ' + element + removedup[element] : removedup[element] <= 1 ? element : element + removedup[element]
      })
      // console.log('this.modalInput.details', this.modalInput.details)
      this.mask.amount = this.numberFormat(sum)
      this.modalInput.amount = sum
      this.modalInput.notes = therealnote
    },
    async handleSubmit() {
      var fd = {
        payment_date: this.modalInput.payment_date.format('YYYY-MM-DD'),
        student_id: this.modalInput.student_id,
        amount: this.modalInput.amount,
        payment_method_id: this.modalInput.payment_method_id,
        notes: this.modalInput.notes,
        details: [],
      }
      this.modalInput.details.forEach(element => {
        var p = {
          payment_schedule_id: element.payment_schedule_id,
          pembayaran: element.pembayaran,
        }
        fd.details.push(p)
      })
      var res = false
      if (this.action === 'update') {
        fd.id = this.editdata.id
        res = await lou.customUrlPut2('sekolah/payment', fd)
      } else {
        res = await lou.customUrlPost2('sekolah/payment', fd)
      }
      if (res) {
        this.resetForm()
      }
    },
    resetForm() {
      localStorage.removeItem('schoolmanagementformpembayaran')
      this.modalInput = {
        payment_date: moment(),
        student_id: '',
        amount: '',
        payment_method_id: '',
        notes: '',
        details: [],
      }
      this.$router.push('/schoolmanagement/pembayaran/data')
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },

    //
    customRow(record, index) {
      return {
        on: {
          click: (event) => {
            // console.log('record', record)
            // console.log('index', index)
            // console.log('event', event)
          },
          contextmenu: (event) => {
            event.preventDefault()
          },
        },
      }
    },
    findMaster(mastername, key, val, tokey) {
      var ret = ''
      var trgt = this[mastername].findIndex((x) => x[key] === val)
      if (trgt >= 0) {
        // if (mastername === 'masterSchedule') {
        console.log('this[mastername][trgt][tokey]', this[mastername][trgt][tokey])
        // }
        ret = this[mastername][trgt][tokey]
      } else {
        ret = 'Data tidak ditemukan!'
      }
      return ret
    },
    numberFormat(val) {
      var ret = lou.curency(val)
      // console.log('ret', ret)
      if (ret === 'NaN') {
        return 0
      } else {
        return ret
      }
    },
    formatDate(value) {
      return moment(value).format('DD-MM-YYYY')
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
